import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { SparkLineChart } from "@mui/x-charts";

import {
    selectWordChartData,
    selectWordChartDataError,
    selectWordChartDataStatus,
} from "src/slices/wordChartDataSlice";

import { useAppSelector } from "src/store";
import { formatDate } from "src/utils/formatDate";

export default function WordChart({
    wordId,
    svgRef,
    color,
    width = 150,
    height = 50,
}: {
    wordId: number;
    svgRef?: React.MutableRefObject<SVGSVGElement | null>;
    color?: string;
    width?: number;
    height?: number;
}) {
    const theme = useTheme();
    const chartData = useAppSelector(selectWordChartData(wordId));
    const chartDataError = useAppSelector(selectWordChartDataError(wordId));
    const chartDataStatus = useAppSelector(selectWordChartDataStatus(wordId));

    const isIncreasing =
        chartData &&
        chartData.length > 0 &&
        chartData[chartData.length - 1].frequencyPercentage >
            chartData[0].frequencyPercentage;
    const isDecreasing =
        chartData &&
        chartData.length > 0 &&
        chartData[chartData.length - 1].frequencyPercentage <
            chartData[0].frequencyPercentage;

    let chartColor = color ?? theme.palette.warning.main;
    if (isIncreasing && !color) {
        chartColor = theme.palette.success.main;
    }
    if (isDecreasing && !color) {
        chartColor = theme.palette.error.main;
    }

    const minWeightedRelativeFrequency =
        chartData && chartData.length > 0
            ? Math.min(
                  ...chartData.map((data) => Number(data.frequencyPercentage)),
              )
            : undefined;
    const maxWeightedRelativeFrequency =
        chartData && chartData.length > 0
            ? Math.max(
                  ...chartData.map((data) => Number(data.frequencyPercentage)),
              )
            : undefined;

    const dataIsFetched = Boolean(chartDataStatus === "succeeded" && chartData);
    const enoughData = dataIsFetched && chartData?.length > 1;

    return (
        <Box sx={{ flexGrow: 1 }}>
            {["loading", "idle"].includes(chartDataStatus) && (
                <Skeleton variant="rounded" width="150px" height="50px" />
            )}
            {chartDataStatus === "failed" && chartDataError && (
                <Typography color="error">{chartDataError}</Typography>
            )}
            {dataIsFetched && enoughData && (
                <SparkLineChart
                    key={`chart-${wordId}`}
                    data={chartData.map(({ frequencyPercentage }) =>
                        Number(frequencyPercentage),
                    )}
                    xAxis={{
                        scaleType: "time",
                        data: chartData.map(({ date }) => new Date(date)),
                        valueFormatter: formatDate,
                        min: new Date(chartData[0].date),
                        max: new Date(chartData[chartData.length - 1].date),
                    }}
                    yAxis={{
                        max: maxWeightedRelativeFrequency,
                        min: minWeightedRelativeFrequency,
                    }}
                    valueFormatter={(value) => `${value}%`}
                    height={height}
                    width={width}
                    showTooltip
                    showHighlight
                    colors={[chartColor]}
                    ref={svgRef}
                />
            )}
            {dataIsFetched && !enoughData && <Typography>-</Typography>}
        </Box>
    );
}
