import {
    Box,
    Button,
    Grid2,
    Link,
    Paper,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableCellProps,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useContext, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";

import useReservoirClient from "src/hooks/useReservoirClient";
import { GetWordsParams } from "src/services/api";
import {
    BackendWord,
    getWords,
    selectCurrentUserWords,
    selectTotalWords,
    selectTotalWordsInGame,
    selectWordStatuses,
    selectWordsByPage,
} from "src/slices/wordSlice";

import ActionsCell from "src/components/ActionsCell";
import CurrencyDisplay from "src/components/CurrencyDisplay";
import VariationCell from "src/components/VariationCell";
import WordChart from "src/components/WordChart";

import { CURRENCY_SYMBOL, PATHS, TOP_25_WORDS_COUNT } from "src/constants";
import { ContractContext } from "src/contexts/ContractsContext";
import { CurrencyRateContext } from "src/contexts/CurrencyRateContext";
import { WalletContext } from "src/contexts/WalletContext";
import { useAppDispatch, useAppSelector } from "src/store";
import { frequencyToPercentage } from "src/utils/numberFormat";

type OrderByKey = GetWordsParams["orderByKey"];
type Order = "asc" | "desc";

interface HeadCell {
    key: OrderByKey | "action" | "chart";
    label: string;
    sortable: boolean;
    align: TableCellProps["align"];
}

const headCells: readonly HeadCell[] = [
    {
        key: "text",
        label: "Word",
        sortable: true,
        align: "left",
    },
    {
        key: "price",
        label: "Floor price",
        sortable: true,
        align: "right",
    },
    {
        key: "variation_one_hour",
        label: "1h",
        sortable: true,
        align: "right",
    },
    {
        key: "variation_twenty_four_hours",
        label: "24h",
        sortable: true,
        align: "right",
    },
    {
        key: "variation_seven_days",
        label: "7d",
        sortable: true,
        align: "right",
    },
    {
        key: "current_frequency",
        label: "Mindshare (<24h)",
        sortable: true,
        align: "right",
    },
    {
        key: "chart",
        label: "Last 7 days",
        sortable: false,
        align: "center",
    },
    {
        key: "action",
        label: "Ownership",
        sortable: false,
        align: "center",
    },
];

const stickyColumnStyle = {
    position: "sticky",
    left: 0,
    background: "#1E1E1E",
    borderRight: "1px solid rgba(81, 81, 81, 1) !important",
    zIndex: "10 !important",
};

interface EnhancedTableProps {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: HeadCell["key"],
    ) => void;
    orderByDirection: Order;
    orderByKey: Omit<OrderByKey, "frequency_trend">;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { orderByDirection, orderByKey, onRequestSort } = props;
    const createSortHandler =
        (property: HeadCell["key"]) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) =>
                    headCell.sortable ? (
                        <TableCell
                            key={headCell.key as React.Key}
                            sortDirection={
                                orderByKey === headCell.key
                                    ? orderByDirection
                                    : false
                            }
                            align={headCell.align}
                            sx={{
                                ...(index === 0 ? stickyColumnStyle : {}),
                                fontWeight: "bold",
                                padding: 0.5,
                                textAlign: "center",
                            }}
                        >
                            <TableSortLabel
                                active={orderByKey === headCell.key}
                                direction={
                                    orderByKey === headCell.key
                                        ? orderByDirection
                                        : "asc"
                                }
                                onClick={createSortHandler(headCell.key)}
                            >
                                <Typography flex={1}>
                                    {headCell.label}
                                </Typography>
                                {orderByKey === headCell.key ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {orderByDirection === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ) : (
                        <TableCell
                            key={headCell.key as React.Key}
                            align="center"
                        >
                            {headCell.label}
                        </TableCell>
                    ),
                )}
            </TableRow>
        </TableHead>
    );
}

type RowsPerPage = 10 | 25 | 50;
const rowsPerPageOptions: RowsPerPage[] = [10, TOP_25_WORDS_COUNT, 50];

function WordsTable() {
    const navigate = useNavigate();
    const { hash } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const reservoirClient = useReservoirClient();

    const searchTermFromURI = searchParams?.get("search") || "";

    const { contract } = useContext(ContractContext);
    const { rate } = useContext(CurrencyRateContext);
    const currentUserWords = useAppSelector(selectCurrentUserWords);
    const [searchTerm, setSearchTerm] = useState<string>(searchTermFromURI);
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
    const [queryParams, setQueryParams] = useState<GetWordsParams>({
        term: searchTermFromURI,
        orderByDirection: "desc" as Order,
        orderByKey: "current_frequency" as OrderByKey,
        page: 1,
        rowsPerPage: TOP_25_WORDS_COUNT as RowsPerPage,
    });
    const words = useAppSelector(
        selectWordsByPage(queryParams.page),
        shallowEqual,
    );
    const totalWords = useAppSelector(selectTotalWords); // total words in the query, so with filters if we use them
    const statuses = useAppSelector(selectWordStatuses);
    const totalWordsInGame = useAppSelector(selectTotalWordsInGame);
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));
    const { selectedAccount } = useContext(WalletContext);

    useEffect(() => {
        setSearchTerm(searchTermFromURI);
    }, [searchTermFromURI]);

    useEffect(() => {
        setQueryParams((prev) => ({
            ...prev,
            term: debouncedSearchTerm.length > 0 ? debouncedSearchTerm : "",
        }));
    }, [debouncedSearchTerm]);

    const shouldWaitBeforeFetchingWordsData =
        selectedAccount &&
        hash === "#my-words" &&
        (["idle", "loading"].includes(statuses.getCurrentUserWords) ||
            queryParams.words?.length !== currentUserWords.length); // this allows to wait for the query params to have the good value

    // Redirect if #my-words is not applicable
    useEffect(() => {
        if (!selectedAccount && hash === "#my-words") {
            navigate(PATHS.LANDING, { replace: true });
        }
    }, [selectedAccount, navigate, hash]);

    // Update query params according to currentUserWords fetching & hash
    useEffect(() => {
        if (hash === "#my-words" && currentUserWords) {
            setQueryParams((prev) =>
                !prev.words || prev.words.length !== currentUserWords.length
                    ? {
                          ...prev,
                          words: currentUserWords,
                          page: 1,
                      }
                    : prev,
            );
        } else {
            setQueryParams((prev) =>
                prev.words !== null
                    ? {
                          ...prev,
                          words: null,
                          page: 1,
                      }
                    : prev,
            );
        }
    }, [currentUserWords, hash]);

    // Fetch words when appropriate
    useEffect(() => {
        if (shouldWaitBeforeFetchingWordsData) {
            return;
        }

        const promise = dispatch(getWords({ ...queryParams, reservoirClient }));

        return () => {
            promise?.abort();
        };
    }, [
        dispatch,
        queryParams,
        shouldWaitBeforeFetchingWordsData,
        reservoirClient,
    ]);

    const handleRequestSort = (
        _event: React.MouseEvent<unknown>,
        property: HeadCell["key"],
    ) => {
        if (contract) {
            const isAsc =
                queryParams.orderByKey === property &&
                queryParams.orderByDirection === "asc";
            setQueryParams((prev) => ({
                ...prev,
                orderByDirection: isAsc ? "desc" : "asc",
                orderByKey: property as OrderByKey,
                page: 1,
            }));
        }
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
        if (contract) {
            setQueryParams((prev) => ({
                ...prev,
                page: newPage + 1,
            }));
        }
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (contract) {
            setQueryParams((prev) => ({
                ...prev,
                rowsPerPage: parseInt(event.target.value) as RowsPerPage,
                page: 1,
            }));
        }
    };

    const wordClassName = ({
        variationOneHour,
        variationTwentyFourHours,
        variationSevenDays,
        text,
    }: Pick<
        BackendWord,
        | "variationOneHour"
        | "variationTwentyFourHours"
        | "variationSevenDays"
        | "text"
    >) => {
        const emojiRegex = /\p{Emoji}/u;
        const isEmoji = emojiRegex.test(text);

        const biggestVariation = Math.max(
            variationOneHour ?? 0,
            variationTwentyFourHours ?? 0,
            variationSevenDays ?? 0,
        );

        if (biggestVariation > 500) {
            return `on-fire${isEmoji ? "-emoji" : ""}`;
        }

        if (biggestVariation > 20) {
            return isEmoji ? "rainbow-shadow" : "rainbow-text";
        }

        return "";
    };

    return (
        <>
            <Grid2 container spacing={2} my={2}>
                <Grid2 size={{ xxs: 12, sm: 6 }}>
                    <Typography
                        textAlign={isDownSm ? "center" : undefined}
                        mt={2}
                    >
                        <b>
                            Showing {words.length}/{totalWordsInGame} words
                        </b>
                    </Typography>
                </Grid2>
                <Grid2 size={{ xxs: 12, sm: 6 }}>
                    <Stack
                        flexDirection="row"
                        gap={2}
                        justifyContent={isDownSm ? "center" : "end"}
                    >
                        <TextField
                            id="search-word"
                            label="Search"
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                                if (e.target.value) {
                                    setSearchParams({ search: e.target.value });
                                } else {
                                    setSearchParams({});
                                }
                            }}
                            size="small"
                        />
                    </Stack>
                </Grid2>
            </Grid2>
            <TableContainer component={Paper}>
                <Table
                    aria-label="simple table"
                    sx={{ borderCollapse: "separate" }}
                >
                    <EnhancedTableHead
                        orderByDirection={queryParams.orderByDirection}
                        orderByKey={queryParams.orderByKey}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {statuses.getWords === "loading" ? (
                            <>
                                {Array.from({
                                    length: queryParams.rowsPerPage,
                                }).map((_, index) => (
                                    <TableRow key={`fake-row-${index}`}>
                                        {Array.from({
                                            length: headCells.length,
                                        }).map((_, i) => (
                                            <TableCell
                                                align="center"
                                                key={`fake-cell-${index}-${i}`}
                                            >
                                                {i === 6 ? ( // graph
                                                    <Skeleton
                                                        variant="rounded"
                                                        width="150px"
                                                        height="50px"
                                                    />
                                                ) : (
                                                    <Skeleton
                                                        variant="text"
                                                        width="80%"
                                                    />
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </>
                        ) : null}
                        {statuses.getWords == "idle" ||
                        (statuses.getWords == "succeeded" &&
                            words.length === 0) ? (
                            <TableRow>
                                <TableCell colSpan={10} align="center">
                                    <Typography>
                                        No words in game yet. Stay tuned!
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : null}
                        {statuses.getWords == "failed" && words.length == 0 ? (
                            <TableRow>
                                <TableCell colSpan={10} align="center">
                                    <Typography>
                                        Failed loading words.
                                    </Typography>
                                    <Button
                                        onClick={() => {
                                            dispatch(
                                                getWords({
                                                    term:
                                                        debouncedSearchTerm.length >
                                                        0
                                                            ? debouncedSearchTerm
                                                            : null,
                                                    ...queryParams,
                                                    reservoirClient,
                                                }),
                                            );
                                        }}
                                    >
                                        Try again
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ) : null}
                        {statuses.getWords == "succeeded" &&
                            words.length > 0 &&
                            words.map((row) => (
                                <TableRow
                                    key={row.text}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        align="left"
                                        sx={stickyColumnStyle}
                                    >
                                        <Typography
                                            fontWeight="bold"
                                            className={wordClassName(row)}
                                        >
                                            {row.currentFrequency === 0 && (
                                                <>💤&nbsp;</>
                                            )}
                                            {row.isTopWord && <>🔝&nbsp;</>}
                                            <Link
                                                href={`https://x.com/search?q=${row.text}`}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                sx={{
                                                    display: "inline",
                                                    textDecoration: "none",
                                                    ":hover": {
                                                        textDecoration:
                                                            "underline",
                                                    },
                                                }}
                                            >
                                                {row.text}
                                            </Link>
                                            {wordClassName(row).startsWith(
                                                "on-fire",
                                            ) &&
                                                new Array(50)
                                                    .fill(null)
                                                    .map((_, index) => (
                                                        <span
                                                            key={index}
                                                            className="particle"
                                                        ></span>
                                                    ))}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        {rate && (
                                            <CurrencyDisplay
                                                amount={BigInt(row.price)}
                                                currencySymbol={
                                                    CURRENCY_SYMBOL.USD
                                                }
                                                toFixed={2}
                                                sx={{ fontWeight: "bold" }}
                                            />
                                        )}
                                        <CurrencyDisplay
                                            amount={BigInt(row.price)}
                                            currencySymbol={CURRENCY_SYMBOL.ETH}
                                            sx={{ color: "grey" }}
                                            toFixed={6}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <VariationCell
                                            variation={row.variationOneHour}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <VariationCell
                                            variation={
                                                row.variationTwentyFourHours
                                            }
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <VariationCell
                                            variation={row.variationSevenDays}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        {frequencyToPercentage(
                                            row.currentFrequency,
                                        )}
                                        %
                                    </TableCell>
                                    <TableCell align="center" sx={{ py: 1 }}>
                                        <WordChart wordId={row.id} />
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{ minWidth: 210 }}
                                    >
                                        <ActionsCell word={row} />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage="Words per page"
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={totalWords}
                rowsPerPage={queryParams.rowsPerPage}
                page={queryParams.page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    "& .MuiTablePagination-spacer": {
                        order: 2,
                    },
                    "& .MuiTablePagination-selectLabel": {
                        order: 0,
                    },
                    "& .MuiTablePagination-select": {
                        order: 1,
                    },
                    "& .MuiTablePagination-displayedRows": {
                        order: 3,
                    },
                    "& .MuiTablePagination-actions": {
                        order: 4,
                    },
                }}
            />
        </>
    );
}
export default WordsTable;
